import DocumentationPage from "@components/DocumentationPage";
import { useDebouncedEffect } from "@hooks/miscellaneous";
import { TextField } from "@material-ui/core";
import { delay } from "@utils/miscellaneous";
import { useRef, useState } from "react";

export default () => {
    const [value, setValue] = useState("");
    const [counter, setCounter] = useState(0);

    const counterReference = useRef(0);

    const [debouncedValue, setDebouncedValue] = useState("");

    useDebouncedEffect(
        async () => {
            await delay(1000);
            setDebouncedValue(value);
            setCounter(++counterReference.current);
        },
        1000,
        [value]);

    return <DocumentationPage>
        <p>Type in the text field fast or slow, and observe the debouncing.</p>

        <TextField
            value={value}
            onChange={ev => setValue(ev.target.value)}
        />

        <p>{counter}</p>

        <p>{debouncedValue}</p>
    </DocumentationPage>;
}