import { useCallback, useEffect, useRef } from "react";

export const useAsyncEffect = (call: () => Promise<any>, deps: Array<any>) => {
    useEffect(() => {
        call();
    }, deps);
}

export const useDebouncedEffect = (
    effect: () => Promise<any>|any, 
    delayInMilliseconds: number, 
    deps: any[]
) => {
    const callback = useCallback(effect, deps);
    const isLoading = useRef(false);
    const shouldRunAgain = useRef(false);

    useEffect(() => {
        const handler = setTimeout(async () => {
            if(isLoading.current || shouldRunAgain.current) {
                shouldRunAgain.current = true;
                return;
            }

            isLoading.current = true;
            try {
                do {
                    shouldRunAgain.current = false;
                    await Promise.resolve(callback());
                } while(shouldRunAgain.current);
            } finally {
                isLoading.current = false;
                shouldRunAgain.current = false;
            }
        }, delayInMilliseconds);

        return () => {
            clearTimeout(handler);
        };
    }, [callback, delayInMilliseconds]);
}